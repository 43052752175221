// src/theme.js

import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: { main: '#171E28' },
    secondary: { main: '#00b0d1' },
    error: { main: red.A400 },
    background: { default: '#fff' }
  }
});

export default theme;
