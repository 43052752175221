// src/app.js

import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AuthProvider } from './components/auth_context';
import { LocalVotesProvider } from './components/local_votes_context';
import { getCurrentQuarter } from './utils/qualifiers';

import Layout from './views/_layout';
import Root from './views/root';
import Regions from './views/regions';
import Login from './views/login';
import Region from './views/region';
import Pitch from './views/pitch';

import { configureAWSRum } from './utils/logger';

let baseCDNUrl = process.env.REACT_APP_CDN_HOST;

async function regionLoader({ params }) {
  let currentYear = new Date().getUTCFullYear();
  let currentQuarter = getCurrentQuarter();
  let eventKey = `${params.key}-${currentYear}-${currentQuarter}`;

  try {
    let responses = await Promise.all([
      fetch(`${baseCDNUrl}/queries/list_regions`),
      fetch(`${baseCDNUrl}/queries/list_regional_qualifiers`),
      fetch(`${baseCDNUrl}/queries/list_regional_qualifier_startups`),
      fetch(`${baseCDNUrl}/queries/votes_by_event/${eventKey}.json`)
    ]);

    let data = await Promise.all(responses.map(r => r.json()));

    return {
      regionData: {
        regions: data[0],
        regionalQualifiers: data[1],
        regionalQualifierStartups: data[2],
        regionalQualifierVotes: data[3]
      }
    };
  } catch (ex) {
    console.error(ex);
    return {
      regionData: {
        regions: [],
        regionalQualifiers: [],
        regionalQualifierStartups: [],
        regionalQualifierVotes: []
      }
    };
  }
}

async function pitchLoader({ params }) {
  let responses = await Promise.all([
    fetch(`${baseCDNUrl}/startup_pitches/${params.key}`),
    fetch(`${baseCDNUrl}/queries/list_regions`),
    fetch(`${baseCDNUrl}/queries/list_regional_qualifier_startups`)
  ]);

  let data = await Promise.all(responses.map(r => r.json()));

  return {
    pitch: data[0],
    regionData: {
      regions: data[1],
      regionalQualifierStartups: data[2]
    }
  };
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Root />
      },
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '/regions',
        element: <Regions />
      },
      {
        path: '/regions/:key',
        loader: regionLoader,
        element: <Region />
      },
      {
        path: '/pitches/:key',
        loader: pitchLoader,
        element: <Pitch />
      }
    ]
  }
]);

function App() {
  console.log('** Founders LIVE CRA **');

  configureAWSRum();

  return (
    <React.StrictMode>
      <AuthProvider>
        <LocalVotesProvider>
          <RouterProvider router={router} />
        </LocalVotesProvider>
      </AuthProvider>
    </React.StrictMode>
  );
}

export default App;
