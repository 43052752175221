// src/pages/regions.js

import React from 'react';
import theme from '../theme';
import staticData from '../static_data.json';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, Container, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  cardGrid: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
    [theme.breakpoints.down('sm')]: {
      paddingTop: '30%'
    }
  },
  cardContent: {
    flexGrow: 1
  },
  previousStartups: {
    backgroundColor: '#000',
    color: '#FFF',
    height: '100%',
    borderRadius: '5px',
    paddingTop: '2em',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      height: '110px',
      borderRadius: '10px'
    },
    '& span': {
      fontWeight: 500,
      fontSize: '2.2em',
      display: 'block',
      textAlign: 'center'
    }
  }
});

function Region(props) {
  const { region } = props;
  const classes = useStyles();
  const navigate = useNavigate();

  return region.key === 'eprenz-community' ? (
    <Grid item xs={12} sm={6} md={4}>
      <div
        onClick={() => navigate(`/regions/${region.key}`)}
        className={classes.previousStartups}
      >
        <span>
          eprenz <br /> Community
        </span>
      </div>
    </Grid>
  ) : (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        className={classes.card}
        onClick={() => navigate(`/regions/${region.key}`)}
      >
        <CardMedia
          className={classes.cardMedia}
          image={
            `${process.env.REACT_APP_ASSETS_PREFIX}` +
            `/images/region-${region.key}.png`
          }
          title={region.label}
        />
      </Card>
    </Grid>
  );
}

export default function Regions() {
  const classes = useStyles();

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Typography gutterBottom variant="h3" component="h1">
        Regions
      </Typography>
      <Grid container spacing={4}>
        {staticData.regions.map(r => (
          <Region key={r.key} region={r} />
        ))}
      </Grid>
    </Container>
  );
}
