// src/components/local_votes_context.tsx

import React, { useState } from 'react';

const LocalVotesContext = React.createContext();

function LocalVotesProvider({ children }) {
  const [localVotes, setLocalVotes] = useState({});
  const [completedPitches, setCompletedPitches] = useState({});

  return (
    <LocalVotesContext.Provider
      value={{ localVotes, setLocalVotes, completedPitches, setCompletedPitches }}
    >
      {children}
    </LocalVotesContext.Provider>
  );
}

export { LocalVotesContext, LocalVotesProvider };
