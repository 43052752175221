// src/_layout.js

import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { AuthConsumer } from '../components/auth_context';

import { makeStyles, ThemeProvider } from '@material-ui/core/styles';

import {
  AppBar,
  Button,
  CssBaseline,
  IconButton,
  Link,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItem
} from '@material-ui/core';

import { Menu as MenuIcon } from '@material-ui/icons/';

import theme from '../theme';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none'
    }
  },

  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbar: {
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(0, 1) }
  },
  logoContainer: {
    padding: theme.spacing(1, 0, 0),
    marginRight: theme.spacing(1),
    '& img': {
      height: '35px',
      width: '45px',
      cursor: 'pointer',
      [theme.breakpoints.down('sm')]: {
        height: '25px',
        width: '35px'
      }
    }
  },
  topNav: {
    '& a': {
      margin: theme.spacing(0, 2)
    },
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  toolbarTitle: {
    flexGrow: 1,
    cursor: 'pointer',
    fontSize: '2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      width: '30%'
    }
  },
  menuButton: {
    margin: theme.spacing(0, 1),
    [theme.breakpoints.up('md')]: { display: 'none' }
  },
  sideNavList: {
    fontSize: '1.2rem',
    width: 180,
    '& ul li button': {
      margin: '0 auto'
    },
    '& ul li a': {
      '&:visited': { color: theme.palette.text.primary },
      width: '100%',
      textAlign: 'center',
      textDecoration: 'none'
    }
  },
  link: {
    margin: theme.spacing(1, 1.5)
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6)
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[200]
        : theme.palette.grey[700]
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2)
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

function AuthenticatedNavbarLinks() {
  const classes = useStyles();

  return (
    <AuthConsumer>
      {({ logout }) => (
        <Button
          onClick={() => logout()}
          color="primary"
          variant="outlined"
          className={classes.link}
        >
          Sign out
        </Button>
      )}
    </AuthConsumer>
  );
}

function UnauthenticatedNavbarLinks() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Button
      href="/login"
      color="primary"
      variant="outlined"
      className={classes.link}
      onClick={e => {
        e.preventDefault();
        return navigate('/login');
      }}
    >
      Sign in
    </Button>
  );
}

function LayoutAppBar() {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <AppBar
      position="static"
      color="default"
      elevation={0}
      className={classes.appBar}
    >
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <img
            src="/images/founders_live_general_logo_blue.png"
            alt="Founders Live"
            width="100%"
            height="100%"
            onClick={() => (window.location.href = 'https://www.founderslive.com/')}
          />
        </div>
        <Typography
          variant="h6"
          color="inherit"
          noWrap
          className={classes.toolbarTitle}
          onClick={() =>
            (window.location.href = 'https://www.founderslive.com/primetime')
          }
        >
          {' '}
          | <span style={{ color: '#00b0d1' }}>Prime Time</span>
        </Typography>
        <nav className={classes.topNav}>
          <Link
            onClick={e => {
              e.preventDefault();
              return navigate('/regions');
            }}
            href="/regions"
          >
            Regions
          </Link>
          <Link
            onClick={e => {
              e.preventDefault();
              window.location.href =
                'https://www.founderslive.com/primetime#schedule';
            }}
            href="https://www.founderslive.com/primetime#schedule"
          >
            Events
          </Link>
          <AuthConsumer>
            {({ isAuthenticated }) => {
              return isAuthenticated ? (
                <AuthenticatedNavbarLinks />
              ) : (
                <UnauthenticatedNavbarLinks />
              );
            }}
          </AuthConsumer>
        </nav>
        <SideNav />
      </Toolbar>
    </AppBar>
  );
}

function SideNav() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      <IconButton
        edge="start"
        className={classes.menuButton}
        color="inherit"
        aria-label="menu"
        onClick={() => setIsOpen(true)}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
        <div className={classes.sideNavList} onClick={() => setIsOpen(false)}>
          <List>
            <ListItem>
              <a href="https://www.founderslive.com/primetime#schedule">Events</a>
            </ListItem>
            <ListItem>
              <a
                onClick={e => {
                  e.preventDefault();
                  return navigate('/regions');
                }}
                href="/regions"
              >
                Regions
              </a>
            </ListItem>
            <AuthConsumer>
              {({ isAuthenticated, logout }) =>
                isAuthenticated ? (
                  <ListItem>
                    {' '}
                    <Button
                      onClick={() => logout()}
                      color="primary"
                      variant="outlined"
                      className={classes.link}
                    >
                      Sign out
                    </Button>
                  </ListItem>
                ) : (
                  <ListItem>
                    {' '}
                    <Button
                      href="/login"
                      color="primary"
                      variant="outlined"
                      className={classes.link}
                      onClick={e => {
                        e.preventDefault();
                        return navigate('/login');
                      }}
                    >
                      Sign in
                    </Button>
                  </ListItem>
                )
              }
            </AuthConsumer>
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

function Layout() {
  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LayoutAppBar />
        <Outlet />
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default Layout;
