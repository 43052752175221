// src/utils.js

export function isCurrentQualifier(year, quarter, timestamp) {
  let currentTimestamp = timestamp || new Date().toISOString();
  let currentMonth = new Date(currentTimestamp).getUTCMonth();
  let currentYear = new Date(currentTimestamp).getUTCFullYear();
  let currentQuarter = resolveQuarterFromMonth(currentMonth);

  return currentQuarter === quarter && currentYear === year;
}

export function isPastQualifier(year, quarter, timestamp) {
  let currentTimestamp = timestamp || new Date().toISOString();
  let currentMonth = new Date(currentTimestamp).getUTCMonth();
  let currentYear = new Date(currentTimestamp).getUTCFullYear();
  let currentQuarter = resolveQuarterFromMonth(currentMonth);

  return year < currentYear || quarter < currentQuarter;
}

export function currentQuarterEndsAt() {
  let currentTimestamp = new Date().toISOString();

  let currentMonth = new Date(currentTimestamp).getUTCMonth();
  let currentQuarter = resolveQuarterFromMonth(currentMonth);

  let mapping = {
    Q1: 'March 31st',
    Q2: 'June 30th',
    Q3: 'September 30th',
    Q4: 'December 31st'
  };

  return mapping[currentQuarter];
}

export function getCurrentQuarter() {
  let currentTimestamp = new Date().toISOString();
  let currentMonth = new Date(currentTimestamp).getUTCMonth();

  return resolveQuarterFromMonth(currentMonth);
}

export function resolveQuarterFromMonth(month) {
  let quarters = {
    0: 'Q1',
    1: 'Q1',
    2: 'Q1',
    3: 'Q2',
    4: 'Q2',
    5: 'Q2',
    6: 'Q3',
    7: 'Q3',
    8: 'Q3',
    9: 'Q4',
    10: 'Q4',
    11: 'Q4'
  };

  return quarters[month];
}
