// src/root.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import theme from '../theme';
import staticData from '../static_data.json';
import { makeStyles } from '@material-ui/core/styles';
import { isCurrentQualifier } from '../utils/qualifiers';

import { Container, Divider, Grid, Link, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  root: {},
  heroContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(1) },
    backgroundImage:
      `url(${process.env.REACT_APP_ASSETS_PREFIX}` +
      `/images/founders_live_europe_bg.jpg)`,
    '& h1, & h2, & h3': {
      color: 'white',
      textAlign: 'center'
    },
    '& h1': {
      fontWeight: 'bold',
      padding: theme.spacing(6, 0),
      fontSize: '5rem',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0),
        fontSize: '3rem'
      }
    },
    '& h2': {
      fontSize: '3rem',
      padding: theme.spacing(4, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0),
        fontSize: '2rem'
      }
    },
    '& h3': {
      marginTop: theme.spacing(2),
      fontSize: '2rem',
      cursor: 'pointer'
    }
  },
  intro: {
    padding: theme.spacing(5, 0),
    [theme.breakpoints.down('sm')]: { padding: theme.spacing(2, 0) },
    '& h3': {
      [theme.breakpoints.down('sm')]: { fontSize: '1.1rem' },
      fontWeight: 'bold',
      fontSize: '1.7rem'
    },
    '& p': {
      padding: theme.spacing(2, 0),
      fontSize: '1.4rem',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0),
        fontSize: '1rem'
      }
    },
    '& a': {
      color: '#0074D9'
    }
  },
  regions: {
    padding: theme.spacing(5, 0),

    [theme.breakpoints.down('sm')]: { padding: theme.spacing(0.5, 0) },

    '& .leftContainer': {
      cursor: 'pointer',
      backgroundSize: 'cover',
      backgroundImage:
        `url(${process.env.REACT_APP_ASSETS_PREFIX}` +
        `/images/founders_live_global_bg.jpg)`,

      '& h3': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.8rem',
          padding: theme.spacing(0.5, 0)
        },
        color: 'white',
        padding: theme.spacing(5, 0),
        textAlign: 'center'
      }
    },
    '& .regionsLinkContainer': {
      textAlign: 'center',
      padding: theme.spacing(0, 0, 2),
      [theme.breakpoints.down('sm')]: { padding: theme.spacing(1) },
      '& a': {
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: { fontSize: '1.5em' }
      }
    }
  },
  bottomContent: {
    padding: theme.spacing(4, 0),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      '& h4': { fontSize: '1.2rem' }
    }
  }
});

async function resolveNextStartup() {
  let baseUrl = process.env.REACT_APP_API_HOST;

  let regionData = await fetch(`${baseUrl}/regions`).then(r => r.json());

  let currentStartups = regionData.regionalQualifierStartups.filter(r =>
    isCurrentQualifier(r.regionalQualifierYear, r.regionalQualifierQuarter)
  );

  if (currentStartups.length && currentStartups.length > 0) {
    let index = Math.floor(Math.random() * (currentStartups.length - 1));
    return currentStartups[index];
  } else {
    let index = Math.floor(
      Math.random() * (regionData.regionalQualifierStartups.length - 1)
    );
    return regionData.regionalQualifierStartups[index];
  }
}

function Root() {
  const classes = useStyles();
  const navigate = useNavigate();
  let [nextStartup, setNextStartup] = useState();

  useEffect(() => {
    async function fetchData() {
      let resolvedNextStartup = await resolveNextStartup();
      setNextStartup(resolvedNextStartup);
    }
    fetchData();
  }, []);

  async function routeToNextStartup() {
    if (nextStartup) {
      return navigate(`/pitches/${nextStartup.startupKey}`);
    } else {
      // let resolvedNextStartup = regionData.regionalQualifierStartups[index];
      // router.push(`/pitches/${resolvedNextStartup.startupKey}`);
    }
  }

  return (
    <React.StrictMode>
      <div className={classes.heroContainer}>
        <Container maxWidth="lg">
          <Typography variant="h1" component="h1">
            Founders Live Prime Time
          </Typography>
          <Typography variant="h2" component="h2">
            Find, watch and vote for the worlds best startup pitches
          </Typography>
          <Typography
            onClick={routeToNextStartup}
            className={classes.watchNow}
            variant="h3"
            component="h3"
          >
            Watch Now
          </Typography>
        </Container>
      </div>

      <div className={classes.intro}>
        <Container maxWidth="md">
          <Typography variant="h3" component="h3">
            Welcome to Founders Live Prime Time {new Date().getFullYear()}
          </Typography>
          <Typography variant="body1">
            Founders Live Prime Time is a global competition series highlighting
            startup founders who have won a{' '}
            <Link href="http://founderslive.com/" target="_blank">
              Founders Live
            </Link>{' '}
            city pitch competition. Help us determine the top 25 pitches from around
            the world by watching and voting here, and we'll take the top 5 voted
            startups in each region over the year who will compete LIVE at the
            Founders Live Prime Time Regional livestreamed events starting in
            {' ' + new Date().getFullYear()}.
          </Typography>

          <Typography variant="h3" component="h3">
            Event tickets
          </Typography>
          <Typography variant="body1">
            Get your Free Tickets to
            <Link
              href="https://primetime.founderslive.com/prime-time-events"
              target="_blank"
            >
              {' '}
              Founders Live Prime Time Events Here
            </Link>
          </Typography>

          <Typography variant="h3" component="h3">
            To vote
          </Typography>

          <Typography variant="body1">
            Simply click a Region to find and watch the qualified startup pitches.
            Finalists are determined by the top ranked pitch at the end of each
            quarter. Watch and vote for as many as you'd like, but you can only vote
            once for each pitch!
          </Typography>
        </Container>
      </div>

      <div className={classes.regions}>
        <Container maxWidth="md">
          <Grid container>
            <Grid
              className="leftContainer"
              item
              xs={12}
              md={8}
              // onClick={(e) => {
              //   router.push("/regions");
              // }}
            >
              <Typography variant="h3" component="h3">
                Founders Live
              </Typography>
              <Typography variant="h3" component="h3">
                Prime Time Regions
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              {staticData.regions.map(r => (
                <div key={r.key} className="regionsLinkContainer">
                  <Link
                    // onClick={(e) => {
                    //   e.preventDefault();
                    //   router.push(`/regions/${r.key}`);
                    // }}
                    href={`/regions/${r.key}`}
                  >
                    {r.label}
                  </Link>
                </div>
              ))}
            </Grid>
          </Grid>
        </Container>
      </div>
      <Divider />
      <div className={classes.bottomContent}>
        <Link href="http://founderslive.com/" target="_blank">
          <Typography variant="h4" component="h4">
            Learn More and Join Founders Live
          </Typography>
        </Link>
      </div>
    </React.StrictMode>
  );
}

export default Root;
