// src/utils/scorekeepr.js

// id: "recpy8oar5f5hODOi"
// key: "north-america-2023-Q1-seattle-q1-2023-test-startup"
// regionKey: "north-america"
// regionQualifierKey: "north-america-2023-Q1"
// regionalQualifierId: "recSzCofZgbIjDOnr"
// regionalQualifierQuarter: "Q1"
// regionalQualifierYear: 2023
// startupCity: "Seattle"
// startupCountry: "United States"
// startupId: "reczBypngVYdV1wJS"
// startupKey: "seattle-q1-2023-test-startup"
// startupName: "Q1 2023 TEST Startup"
// votes: 4

// regionalQualifierVotes
// [{seattle-q1-2023-test-startup: 6}]

// update model:
// [{ "key": "seattle-q1-2023-test-startup", "votes": 6 }]

export function mapEventVotesToRegionPitches(
  regionPitches,
  eventVotes,
  refreshedRegionVotes
) {
  let updatedData = regionPitches.map(rp => {
    let rpv = eventVotes.find(ev => ev.key === rp.startupKey);

    let votes = 0;

    if (refreshedRegionVotes[rp.startupKey]) {
      votes = refreshedRegionVotes[rp.startupKey];
    } else if (rpv) {
      votes = rpv.votes;
    }

    return { ...rp, votes };
  });

  return updatedData;
}
