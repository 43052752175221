// src/login.js

import React from 'react';
import { Formik } from 'formik';
import { AuthConsumer } from '../components/auth_context';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

function RequestOTP() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let redirect = searchParams.get('redirect');
  let redirectTo = redirect ? `&redirect=${redirect}` : '';

  return (
    <AuthConsumer>
      {({ /* isAuthenticated, login */ register }) => {
        return (
          <React.Fragment>
            <Formik
              initialValues={{ email: '' }}
              validate={values => {
                const errors = {};
                if (!values.email) {
                  errors.email = 'Required';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Invalid email address';
                }
                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {
                setStatus();
                setTimeout(() => {
                  try {
                    return register(values.email)
                      .then(() => {
                        setSubmitting(false);
                        navigate(
                          `/login?email=${encodeURIComponent(
                            values.email
                          )}${redirectTo}`
                        );
                      })
                      .catch(ex => {
                        console.error(ex);
                        setSubmitting(false);
                        setStatus(
                          'It looks like there was an issue, please try again.'
                        );
                      });
                  } catch (err) {
                    console.error(err);
                    setStatus(
                      'It looks like there was an issue, please try again.'
                    );
                  }

                  setSubmitting(false);
                }, 200);
              }}
            >
              {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
                /* and other goodies */
              }) => (
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    id="email"
                    name="email"
                    label="Email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />

                  {status && <p>{status}</p>}

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </form>
              )}
            </Formik>
          </React.Fragment>
        );
      }}
    </AuthConsumer>
  );
}

function SubmitOTP(props) {
  const classes = useStyles();
  const { email } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  let redirect = searchParams.get('redirect');
  let redirectTo = redirect ? redirect : '/regions';

  return (
    <AuthConsumer>
      {({ login }) => {
        return (
          <Formik
            initialValues={{ otp: '' }}
            validate={values => {
              const errors = {};
              if (!values.otp) {
                errors.otp = 'Required';
              } else if (!/^[0-9]+$/i.test(values.otp)) {
                errors.email = 'Invalid one-time-password';
              }
              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              console.log({ msg: 'onsubmit', values });
              setStatus();
              setTimeout(() => {
                try {
                  return login(email, values.otp)
                    .then(() => {
                      setSubmitting(false);
                      navigate(redirectTo);
                    })
                    .catch(ex => {
                      console.error(ex);
                      setSubmitting(false);
                      setStatus(
                        'It looks like there was an issue, please try again.'
                      );
                    });
                } catch (err) {
                  console.error(err);
                  setStatus('It looks like there was an issue, please try again.');
                }

                setSubmitting(false);
              }, 200);
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
              /* and other goodies */
            }) => (
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  id="otp"
                  name="otp"
                  label="Login code"
                  value={values.otp}
                  onChange={handleChange}
                  error={touched.otp && Boolean(errors.otp)}
                  helperText={touched.otp && errors.otp}
                />

                {status && <p>{status}</p>}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  disabled={isSubmitting}
                >
                  Submit
                </Button>
              </form>
            )}
          </Formik>
        );
      }}
    </AuthConsumer>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  formHeader: {
    margin: theme.spacing(2, 0)
  },
  submit: {
    padding: theme.spacing(1),
    margin: theme.spacing(3, 0)
  }
}));

export default function Register() {
  const [searchParams] = useSearchParams();
  const classes = useStyles();
  const email = searchParams.get('email');

  console.log({ email });

  return (
    <Container component="main" maxWidth="sm">
      {email ? (
        <React.Fragment>
          <Typography
            style={{ textAlign: 'center' }}
            className={classes.formHeader}
            component="h1"
            variant="h5"
          >
            Prime Time Voting Step 2:
          </Typography>
          {[
            'You’re almost there!',
            'Enter the code we emailed to you and you’ll be registered to vote in the Prime Time Pitch Challenge!'
          ].map(item => (
            <Typography
              style={{ textAlign: 'center' }}
              component="p"
              variant="body1"
            >
              {item}
            </Typography>
          ))}
          <Container maxWidth="xs">
            <div className={classes.paper}>
              <SubmitOTP email={email} />
            </div>
          </Container>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography
            style={{ textAlign: 'center' }}
            className={classes.formHeader}
            component="h1"
            variant="h5"
          >
            Prime Time Voting Step 1:
          </Typography>
          {[
            'Enter your email address below so we can send you a code that helps us keep the voting fair and bot-free.',
            'We’ll keep you updated on the competition and other Founders Live news.',
            'Your info will never be shared with anyone, and you can unsubscribe at any time.'
          ].map(item => (
            <Typography
              style={{ textAlign: 'center' }}
              component="p"
              variant="body1"
            >
              {item}
            </Typography>
          ))}
          <Container maxWidth="xs">
            <div className={classes.paper} maxWidth="xs">
              <RequestOTP />
            </div>
          </Container>
        </React.Fragment>
      )}
    </Container>
  );
}
