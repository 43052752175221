// src/components/auth_context.tsx

import React from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = React.createContext();

let baseUrl = process.env.REACT_APP_API_HOST;

class AuthProvider extends React.Component {
  state = {
    submitting: false,
    isAuthenticated: false,
    email: null,
    authToken: null
  };

  constructor() {
    super();
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    try {
      const authToken = localStorage.getItem('token');
      if (authToken) {
        const email = jwtDecode(authToken).email;
        this.setState({ isAuthenticated: true, email, authToken });
      }
    } catch (e) {
      console.error(e.message, e.stack);
    }
  }

  register(email) {
    return fetch(`${baseUrl}/otp`, {
      method: 'POST',
      body: JSON.stringify({ email }),
      headers: { 'content-type': 'application/json' }
    }).then(res => {
      if (res.status === 201) {
        return res.json();
      }
      console.log({ status: res.status });
      throw new Error('unable to complete OTP request');
    });
  }

  login(email, otp) {
    return fetch(`${baseUrl}/auth/tokens`, {
      method: 'POST',
      body: JSON.stringify({ email, otp }),
      headers: { 'content-type': 'application/json' }
    })
      .then(res => {
        if (res.status === 201) {
          return res.json();
        }
        console.log({ status: res.status });
        throw new Error(
          'There was an issue logging in, try requesting another login code.'
        );
      })
      .then(body => {
        const email = jwtDecode(body.token).email;

        this.setState({
          isAuthenticated: true,
          submitting: false,
          email,
          authToken: body.token
        });

        localStorage.setItem('token', body.token);
      });

    // return requestOtp(email).then(() => {
    //   console.log('completed otp request');
    // });

    // if (isValidLogin(email /*, password */)) {
    //   this.setState({
    //     isAuthenticated: true,
    //     submitting: false,
    //     email,
    //   });

    //   localStorage.setItem("token", email);
    // } else {
    //   throw new Error("invalid login");
    //   // return { errors: { summary: "invalid login" } };
    //   // return Promise.reject(new Error("invalid login"));
    // }
  }

  logout() {
    this.setState({ isAuthenticated: false, email: undefined });
    try {
      localStorage.removeItem('token');
    } catch (e) {
      console.error(e.message, e.stack);
    }
  }

  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthenticated: this.state.isAuthenticated,
          authToken: this.state.authToken,
          email: this.state.email,
          submitting: this.state.submitting,
          login: this.login,
          logout: this.logout,
          register: this.register
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

export { AuthProvider, AuthConsumer, AuthContext };
