// src/utils/logger.js

import { AwsRum } from 'aws-rum-web';

let logger;

export function log(label, data) {
  try {
    logger.recordEvent(label, data);
  } catch (ex) {
    console.error(ex.message);
  }
}

export function configureAWSRum() {
  if (process.env.REACT_APP_ENABLE_LOGGER === 'true') {
    console.log('injecting aws rum');

    try {
      const config = {
        sessionSampleRate: 1,
        guestRoleArn:
          'arn:aws:iam::751151400683:role/RUM-Monitor-us-west-2-751151400683-8187230510861-Unauth',
        identityPoolId: 'us-west-2:af3c7b78-1ea9-44f4-bfac-82e824198ff2',
        endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
        telemetries: ['errors', 'http'],
        allowCookies: true,
        enableXRay: false
      };

      const APPLICATION_ID = '9242f499-3d8a-4a71-9a88-04cc18b2421c';
      const APPLICATION_VERSION = '1.0.0';
      const APPLICATION_REGION = 'us-west-2';

      logger = new AwsRum(
        APPLICATION_ID,
        APPLICATION_VERSION,
        APPLICATION_REGION,
        config
      );
    } catch (error) {
      // Ignore errors thrown during CloudWatch RUM web client initialization
    }
  }
}
